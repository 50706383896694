import Swiper from "../../node_modules/swiper/swiper-bundle.esm";

(function () {
    const breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280,
        xxl: 1680,
    };

    const effects = [
        "slide",
        "fade",
        "cube",
        "coverflow",
        "flip",
        "creative",
        "cards",
    ];

    const swiperSizes = {
        fourth: 4,
        half: 2,
        third: 3,
        full: 1,
    };

    const swiperOptions = {
        // Optional parameters
        direction: "horizontal",
        loop: true,
        autoHeight: false,
        slideClass: "swiper__slide",
        createElements: true,
        watchOverflow: true,

        lazy: {
            // Disable preloading of all images
            preloadImages: false,
            // Enable lazy loading
            lazy: true,
            //  tell swiper to load images before they appear
            loadPrevNext: true,
            // amount of images to load
            loadPrevNextAmount: 4,
            // Disable preloading of all images
        },

        // Navigation arrows
        navigation: {
            nextEl: ".swiper__button--next",
            prevEl: ".swiper__button--prev",
            disabledClass: "swiper__button--disabled",
        },

        speed: 500,
        spaceBetween: 40,
        effect: effects[0],
        grabCursor: true,

        breakpoints: {
            [`${breakpoints.xs}`]: {
                slidesPerView: 1.2,
                centeredSlides: true,
            },
            [`${breakpoints.lg}`]: {
                slidesPerView: 2,
                centeredSlides: false,
            },
        },
    };

    const swipers = [...document.querySelectorAll(".swiper")];

    swipers.forEach((swiper) => {
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        Object.entries(swiperSizes).forEach(function (swiperSize) {
            const [key, value] = swiperSize;
            if (swiper.classList.contains(`swiper--${key}`)) {
                swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                    slidesPerView: value,
                    centeredSlides: false,
                };
            }
        });

        if (swiper.classList.contains("swiper--posts")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 4,
            };
        }

        if (swiper.classList.contains("swiper--image")) {
            swiperOptions.autoHeight = true;
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // If you want to overwrite a swiper carousel do it between the "Begin overwrite swiper carousels" and "End overwrite swiper carousels"

        if (swiper.classList.contains("swiper--hero")) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 1;

            // Overrides the default breakpoint options with nothing, so the above options are used
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
            swiperOptions.breakpoints[`${breakpoints.lg}`] = {};
        }

        if (swiper.classList.contains("swiper--logo")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;
            swiperOptions.slidesPerView = 6;

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
        }

        if (swiper.classList.contains("swiper--image-video")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = true;
            swiperOptions.slidesPerView = 3;
            swiperOptions.spaceBetween = 33;
            swiperOptions.navigation = {
                nextEl: swiper
                    .closest(".pane")
                    .querySelector(".swiper__button--next"),
                prevEl: swiper
                    .closest(".pane")
                    .querySelector(".swiper__button--prev"),
                disabledClass: "swiper__button--disabled",
            };

            swiperOptions.lazy = {
                lazy: true,
                // loadPrevNext: true,
                // loadPrevNextAmount: 10,
                // preloadImages: false,
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.2,
                centeredSlides: true,
                spaceBetween: 16,
            };

            swiperOptions.breakpoints[`${breakpoints.md}`] = {
                slidesPerView: 2.2,
                centeredSlides: false,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3,
            };
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-

        const swiperDefault = new Swiper(swiper, swiperOptions);
    });
})();
