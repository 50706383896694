import { ScrollSpy } from "bootstrap";

new ScrollSpy(document.body, {
    target: ".main-header__container",
});

new ScrollSpy(document.body, {
    target: "[data-menu-container]",
});

const links = document.querySelectorAll(".menu__link");

if (links) {
    links.forEach((link) => {
        const href = link.getAttribute("href");

        if (href !== "" && href !== "#") {
            if (document.querySelector(`${href}`)) {
                link.addEventListener("click", (e) => {
                    e.preventDefault();

                    document.querySelector(`${href}`).scrollIntoView({
                        block: "start",
                        behavior: "smooth",
                    });
                });
            }
        }
    });
}
