import { gsap } from "gsap";

import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

// const tl = gsap.timeline({
//     scrollTrigger: {
//         trigger: "#hero",
//         scrub: true,
//         pin: true,
//         start: "top top",
//         end: "+=30%",
//         anticipatePin: 1,
//     },
// });

// tl.to(".hero-onepager__note--left", {
//     y: -150,
//     duration: 1.3,
// });

// tl.to(
//     ".hero-onepager__note--right",
//     {
//         y: -350,
//         duration: 1.3,
//     },
//     "<"
// );

// gsap.set(".animate-car", { scale: 0.5, autoAlpha: 1 });

// gsap.to(".animate-car", {
//     duration: 5,
//     immediateRender: true,
//     motionPath: {
//         path: "#foodtruck",
//         align: "#foodtruck",
//         alignOrigin: [0.5, 0.5],
//         autoRotate: true,
//         start: 0.4,
//         end: 1.5,
//     },
// });

// MotionPathHelper.create(".animate-car");
